<template>
  <div>
    <v-overlay :value="overlay" absolute style="z-index:999999999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card class="pa-4">
      <v-toolbar flat>
        <v-toolbar-title>Tienda</v-toolbar-title>
        <v-divider vertical class="mx-4"></v-divider>
        <v-btn small color="primary" @click="abrirDialogProductStore()"
          ><v-icon small>mdi-plus</v-icon>Crear tienda</v-btn
        >
      </v-toolbar>
      <v-data-table :items="listaProductStore" :headers="headersProductStores">
        <template v-slot:item.opciones="{ item }">
          <v-btn small icon @click="abrirDialogProductStore(item)"
            ><v-icon small color="primary">mdi-pencil</v-icon></v-btn
          >

          <v-btn small icon @click="borrarProductStore(item)"
            ><v-icon small color="red">mdi-delete</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogProductStore" width="600" persistent>
      <v-card>
        <v-toolbar color="blue" flat height="40" dark>
          <v-toolbar-title>Tienda</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn small icon @click="dialogProductStore = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-row class="pa-2 ma-0"
          ><v-col cols="12">
            <v-text-field
              v-model="datosProductStore.name"
              label="Nombre de la tienda"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="datosProductStore.name"
              label="Nombre de la empresa"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="ma-0 pt-4">
          <v-col cols="8"
            ><v-btn small color="primary" @click="guardarProductStore()">
              <v-icon small>mdi-content-save</v-icon>Guardar
            </v-btn></v-col
          >

          <v-col cols="4"
            ><v-btn small color="blue" text @click="dialogProductStore = false">
              <v-icon small>mdi-cancel</v-icon>Cancelar
            </v-btn></v-col
          >
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "ProductStore",
  components: {},
  data: () => ({
    show: false,

    requiredRule: [(v) => !!v || "El campo es requerido"],
    options: {},
    setfilters: [],
    selected: [],
    users: [],
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 20,
    pageCount: 0,
    overlay: false,
    required: [(v) => !!v || "El campo es requerido"],

    datosProductStore: {},
    listaProductStore: [],
    headersProductStores: [
      { text: "Opciones", align: "start", value: "opciones" },
      { text: "Codigo", align: "start", value: "product_store_id" },
      { text: "Nombre", align: "start", value: "store_name" },
      { text: "Empresa", align: "start", value: "company_name" },
    ],

    dialogProductStore: false,
  }),
  computed: {
    ...mapState("master", ["loadingBtn", "companies"]),
    ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),
    ...mapState("master", ["loadingTable", "tenantId", "user"]),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setLoadingTable",
      "setTitleToolbar",
      "setTenantId",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapActions("access", []),

    cargarDatosProductStore(id) {
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("product-store/" + id);
      this.requestApi({
        method: "GET",
        data: {
          typeFind: "cargarDatosProductStore",
          work_effort_id: id,
        },
      })
        .then((res) => {
          this.datosProductStore = res.data.product_store;
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },
    cargarListaProductStore() {
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("product-store");
      this.requestApi({
        method: "GET",
        data: {
          tipo_lista: "listaProductStore",
        },
      })
        .then((res) => {
          this.listaProductStore = res.data._embedded.product_store;
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },
    modificarProductStore() {},
    crearProductStore() {
      if (!this.$refs.form_estatus_evento.validate()) return false;

      Vue.swal({
        html: `Esta seguro de actualizar el estado del evento?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.overlay = true;

          this.setUrl("evento");

          const { work_effort_id } = this.eventoEstado;

          this.requestApi({
            method: "POST",
            data: {
              tenantId: this.tenantId,
              workEffortId: this.datosEvento.work_effort_id,
              statusId: this.nuevoEstadoId,
              motivo: this.motivo,
              motivoId: this.nuevoMotivoId,
              typeStore: "actualizaEstadoEvento",
            },
          })
            .then((res) => {
              this.alertNotification({
                param: {
                  html: res.data.res.msg,
                },
              });

              this.cargarDatosProductStore("");
              this.dialogEstadoEvento = false;
              this.motivo = null;
              this.nuevoEstadoId = null;
              this.nuevoMotivoId = null;
              this.overlay = false;
            })
            .catch((err) => {
              this.overlay = false;
              console.log(err);
            });
        }
      });
    },
    abrirDialogProductStore(item) {
      this.dialogProductStore = true;
    },
    borrarProductStore(item) {},
  },
  mounted() {
    this.setTitleToolbar("ESTABLECIMIENTO");
    this.cargarListaProductStore();
  },
};
</script>
